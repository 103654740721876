import React, { useState, useEffect, useRef } from "react";
import "react-simple-keyboard/build/css/index.css";
import { filter, map, isEmpty } from "lodash";
//import { bannerList } from "../../config/game-configs";
import GameListCarousel from "components/game-list/carousel";
import GameListGrid from "components/game-list/grid";
import GameListBanner from "components/game-list/banner"; 
import { useSelector } from "react-redux"; 
import GameMenu from "components/game-list/game-menu";   
import ApiEngine from "../../util/ApiEngine";  
import { ApiKey, ApiUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes, TransactionType, BankingChannel, PanelType } from "../../util/Constant";
import { useDispatch } from "react-redux";   
import {
    showResponseMessage,
    setBusy,
    setIdle,
    showMessage,
} from "../../redux/AppAction.js";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import {
    createFormBody, stringIsNullOrEmpty, createMultiPartFormBody
} from "../../util/Util";
import PinInput from "react-pin-input";
import {
    Modal,
    ModalBody,
    TabContent,
    TabPane,
    Nav, 
    NavItem,
    NavLink,
} from "reactstrap";
import Keyboard from "react-simple-keyboard";
import Count from "components/timer/Count";
import Slider from "react-slick";
import parse from "html-react-parser";
import moment from "moment";
import Draggable from 'react-draggable';
import Spinner from 'react-bootstrap/Spinner';
import LiveChatModal from 'components/custom/LiveChatModal';

const Home = (props) => {
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();
    const [newGames, setNewGames] = useState({});
    const [recommendedGames, setRecommendedGames] = useState({});
    const [displayGameMenu, setDisplayGameMenu] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    const [isFilterNewDropdownVisible, setIsFilterNewDropdownVisible] =
        useState(false);
    const [isFilterPopularDropdownVisible, setIsFilterPopularDropdownVisible] =
        useState(false);
    const gameCategories = useSelector((state) =>
        filter(state.gameState.gameCategories, (item) => {
            return item.id > 0;
        })
    );
    const { isLoggedIn } = useSelector((state) => state.authState);
    const _userData = useSelector((state) => state.authState.userData);

    const [memberData, setMemberData] = useState({});
    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const keyboard = useRef();

    const [showCreateShareholderPin, setShowCreateShareholderPin] =
        useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [loadingMemberFlag, setLoadingMemberFlag] = useState(true);
    const [mainBannerData, setMainBannerData] = useState();
    const [mainBannerImg, setMainBannerImg] = useState("");
    const [sliderBannerList, setSliderBannerList] = useState([]);
    const [sportGames, setSportGames] = useState();
    const [sportGameCount, setSportGameCount] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const [displayBanner, setDisplayBanner] = useState(false);
    const bannerSlider = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        lazyLoad: true,
        autoplay: true
    };

    // const sportSlider = {
    //   slidesToShow: 3,
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   autoplay: true,
    //   responsive: [
    //     {
    //       breakpoint: 1200,
    //       settings: {
    //         slidesToShow: 3,
    //       },
    //     },
    //   ],
    // };

    const [bonus, setBonus] = useState(0);
    const [commission, setCommission] = useState(0);
    const [clubFee, setClubFee] = useState(0);
    const [selectedGameName, setSelectedGameName] = useState("");
    const [productGameId, setProductGameId] = useState(0);
    const [masterProductId, setMasterProductId] = useState(0);
    const [walletList, setWalletList] = useState([]);
    const [fromOptions, setFromOptions] = useState([]);
    const [toOptions, setToOptions] = useState([]);
    const [packageNameKeyword, setPackageNameKeyword] = useState("");
    const [isNewWindow, setIsNewWindow] = useState(false);
    const [isApp, setIsApp] = useState(false);
    const [toLobbyBtn, setToLobbyBtn] = useState(false);
    const [appUrl, setAppUrl] = useState("");
    const [appDeepLink, setAppDeepLink] = useState("");
    const [showPregamePopup, setShowPregamePopup] = useState(false);
    const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
        useState(0);
    const [mpData, setMpData] = useState([]);
    const [title, setTitle] = useState("");
    const [html, setHtml] = useState("");
    const [theInterval, setTheInterval] = useState(false);
    const [addRightCss, setAddRightCss] = useState("");
    const [dNone, setDNone] = useState("");
    const [showTrigger, setShowTrigger] = useState(true);
    const [cnyModal, setCnyModal] = useState(false);
    const [currentX, setCurrentX] = useState(0);
    const [currentY, setCurrentY] = useState(0);
    const [dragging, setDragging] = useState(false);
    const [cnyEndTime, setCnyEndTime] = useState(moment("2023-02-05 23:59:59:999").format("YYYY-MM-DD HH:mm:ss:SSS"));
    const [currentTime, setCurrentTime] = useState(moment().format("YYYY-MM-DD HH:mm:ss:SSS"));

    const [startSpinning, setStartSpinning] = useState(false);
    const [freeSpinItems, setFreeSpinItems] = useState([]);
    const [wheelOuterBorderColor, setWheelOuterBorderColor] = useState("");
    const [wheelInnerBorderColor, setWheelInnerBorderColor] = useState("");
    const [winningId, setWinningId] = useState(0);
    const [winningOption, setWinningOption] = useState();
    const [showWinningText, setShowWinningText] = useState(false);
    const [showFreeSpin, setShowFreeSpin] = useState(false);
    const [showTotalSpinText, setShowTotalSpinText] = useState(0);
    const [hasSpinOnce, setHasSpinOnce] = useState(false);
    const [isFreeSpinWin, setIsFreeSpinWin] = useState(true);

    const [openMiniGameModal, setOpenMiniGameModal] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState("COINMINI");
    const [coinMiniGameUrl, setCoinMiniGameUrl] = useState("");
    const [kenoMiniGameUrl, setKenoMiniGameUrl] = useState("");
    const [taiXiuMiniGameUrl, setTaiXiuMiniGameUrl] = useState("");
    const [miniGamesFirstClicked, setMiniGamesFirstClicked] = useState(["COINMINI"]);
    const [showMiniGameTrigger, setShowMiniGameTrigger] = useState(JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)));
    const [controlledPosition, setControlledPosition] = useState({});
    const [startGameTime, setStartGameTime] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [games93Connect, setGames93Connect] = useState({});
    const delayTime = 4000;
    const [content, setContent] = useState("");
    const onControlledDrag = (e, position) => {
        const { x, y } = position;
        setControlledPosition({ x, y });
    }
    const toggle = tab => {
        if (currentActiveTab !== tab) {
            if (startGameTime != "") {
                const withinSecond = new Date().getTime() - startGameTime < delayTime;
                if (miniGamesFirstClicked.indexOf(tab) == -1) {
                    if (!withinSecond) {
                        setCurrentActiveTab(tab);
                        setIsLoading(true);
                        startMiniGame(tab, 'd');
                        setTimeout(function () {
                            setMiniGamesFirstClicked([...miniGamesFirstClicked, tab]);
                            setIsLoading(false);
                        }, delayTime);
                    }
                }
                else if (miniGamesFirstClicked.indexOf(currentActiveTab) != -1) {
                    setCurrentActiveTab(tab);
                }
            }
        }
    }

    const [currentWindowWidth, setCurrentWindowWidth] = useState(
        Math.max(document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        )
    );
    const [currentWindowHeight, setCurrentWindowHeight] = useState(
        Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        )
    );

    // const elementHeader = document.getElementById("header");
    const elementHeader = document.getElementById("header-wrapper");
    const headerHeight = elementHeader.offsetHeight * -1;

    const sportSlider = {
        slidesToShow: sportGameCount > 3 ? 3 : sportGameCount,
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: sportGameCount,
                },
            },
        ],
    };

    const fifaGroups = [
        {
            group_title: "Group A",
            group_list: [
                {
                    title: "A1",
                    flag: require("../../assets/img/sports/a1.png"),
                    country: "Qatar",
                },
                {
                    title: "A2",
                    flag: require("../../assets/img/sports/a2.png"),
                    country: "Ecuador",
                },
                {
                    title: "A3",
                    flag: require("../../assets/img/sports/a3.png"),
                    country: "Senegal",
                },
                {
                    title: "A4",
                    flag: require("../../assets/img/sports/a4.png"),
                    country: "Netherlands",
                },
            ]
        },
        {
            group_title: "Group B",
            group_list: [
                {
                    title: "B1",
                    flag: require("../../assets/img/sports/b1.png"),
                    country: "England",
                },
                {
                    title: "B2",
                    flag: require("../../assets/img/sports/b2.png"),
                    country: "IR Iran",
                },
                {
                    title: "B3",
                    flag: require("../../assets/img/sports/b3.png"),
                    country: "USA",
                },
                {
                    title: "B4",
                    flag: require("../../assets/img/sports/b4.png"),
                    country: "Wales",
                },
            ]
        },
        {
            group_title: "Group C",
            group_list: [
                {
                    title: "C1",
                    flag: require("../../assets/img/sports/c1.png"),
                    country: "Argentina",
                },
                {
                    title: "C2",
                    flag: require("../../assets/img/sports/c2.png"),
                    country: "Saudi Arabia",
                },
                {
                    title: "C3",
                    flag: require("../../assets/img/sports/c3.png"),
                    country: "Mexico",
                },
                {
                    title: "C4",
                    flag: require("../../assets/img/sports/c4.png"),
                    country: "Poland",
                },
            ]
        },
        {
            group_title: "Group D",
            group_list: [
                {
                    title: "D1",
                    flag: require("../../assets/img/sports/d1.png"),
                    country: "France",
                },
                {
                    title: "D2",
                    flag: require("../../assets/img/sports/d2.png"),
                    country: "Denmark",
                },
                {
                    title: "D3",
                    flag: require("../../assets/img/sports/d3.png"),
                    country: "Tunisia",
                },
                {
                    title: "D4",
                    flag: require("../../assets/img/sports/d4.png"),
                    country: "Australia",
                },
            ]
        },
        {
            group_title: "Group E",
            group_list: [
                {
                    title: "E1",
                    flag: require("../../assets/img/sports/e1.png"),
                    country: "Spain",
                },
                {
                    title: "E2",
                    flag: require("../../assets/img/sports/e2.png"),
                    country: "Germany",
                },
                {
                    title: "E3",
                    flag: require("../../assets/img/sports/e3.png"),
                    country: "Japan",
                },
                {
                    title: "E4",
                    flag: require("../../assets/img/sports/e4.png"),
                    country: "Costa Rica",
                },
            ]
        },
        {
            group_title: "Group F",
            group_list: [
                {
                    title: "F1",
                    flag: require("../../assets/img/sports/f1.png"),
                    country: "Belgium",
                },
                {
                    title: "F2",
                    flag: require("../../assets/img/sports/f2.png"),
                    country: "Canada",
                },
                {
                    title: "F3",
                    flag: require("../../assets/img/sports/f3.png"),
                    country: "Morocco",
                },
                {
                    title: "F4",
                    flag: require("../../assets/img/sports/f4.png"),
                    country: "Croatia",
                },
            ]
        },
        {
            group_title: "Group G",
            group_list: [
                {
                    title: "G1",
                    flag: require("../../assets/img/sports/g1.png"),
                    country: "Brazil",
                },
                {
                    title: "G2",
                    flag: require("../../assets/img/sports/g2.png"),
                    country: "Serbia",
                },
                {
                    title: "G3",
                    flag: require("../../assets/img/sports/g3.png"),
                    country: "Switzerland",
                },
                {
                    title: "G4",
                    flag: require("../../assets/img/sports/g4.png"),
                    country: "Cameroon",
                },
            ]
        },
        {
            group_title: "Group H",
            group_list: [
                {
                    title: "H1",
                    flag: require("../../assets/img/sports/h1.png"),
                    country: "Portugal",
                },
                {
                    title: "H2",
                    flag: require("../../assets/img/sports/h2.png"),
                    country: "Ghana",
                },
                {
                    title: "H3",
                    flag: require("../../assets/img/sports/h3.png"),
                    country: "Uruguay",
                },
                {
                    title: "H4",
                    flag: require("../../assets/img/sports/h4.png"),
                    country: "Korea Republic",
                },
            ]
        },
    ];

    async function submitPin(pin, conf) {
        try {
            let params = {
                pinNumber: pin,
                confirmPinNumber: conf,
            };
            let responseJson = await ApiEngine.post(
                ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
                createFormBody(params)
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setShowCreateShareholderPin(false);
                setInvalidPin(false);
            }
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );

            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(0);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(0);
        } catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    const adjustFocus = () => {
        if (eleConfirm["current"] !== null) {
            if (
                eleConfirm["current"]["values"][0] == "" ||
                eleConfirm["current"]["values"][0] == undefined
            ) {
                eleConfirm["current"]["elements"][0].focus();
            }
        }
    };




    function onCreatePinKeyPress(value) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (value !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                // elePin["current"]["elements"][currentIndex].state.value = value;
                pinStr = value;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumber(pinStr);
                }
            } else {
                currentIndex = pinNumberConfirmFocused;
                // eleConfirm["current"]["elements"][currentIndex].state.value = value;
                pinConfirmStr = value;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex < 3) {
                    eleConfirm["current"]["elements"][currentIndex].focus();
                } else {
                    setPinNumberConfirm(pinConfirmStr);
                    // setTimeout(function () {
                    //   submitPin(pinNumber, pinConfirmStr);
                    // }, 1000);
                }
            }
        } else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin["current"]["elements"][currentIndex].focus();
        }
    }

    const gameProviders = useSelector((state) =>
        filter(state.gameState.gameProviders, (item) => {
            return item.hasOwnLobby;
        })
    );

    const carouselGameList = useSelector(
        (state) => state.gameState.gameProviders
    );

    const languageId = useSelector((state) => state.languageState.languageId);
    useEffect(() => {
        const scrollEvent = () => {
            window.addEventListener("scroll", (e) => {
                setScrollTop(e.target.documentElement.scrollTop);
            });
        };

        scrollEvent();

        return () => {
            //window.removeEventListener("scroll");
        };
    }, []);

    useEffect(() => {
    }, [scrollTop]);

    useEffect(() => {
        init();
        getSportGames();
        getMarqueeTagContent();

        // setTimeout(() => {
        //   setAddRightCss('move-negative-right');
        // }, 800);

        setTimeout(() => {
            setDNone('d-block');
        }, 1300);

    }, []);

    useEffect(() => {
        getBannerLanguage();
        getMarqueeTagContent();
    }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

    useEffect(() => {
        if (isLoggedIn) {
            getMemberInit();
        }
        else {
            setShowCreateShareholderPin(false);
        }
        setShowMiniGameTrigger(JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)));
    }, [isLoggedIn]);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === true) {
            localStorage.setItem(SessionKey._ISONLOAD, false);
        }
        else if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === false) {
            localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);
            setShowMiniGameTrigger(true);
        }
    }, []);

    window.onbeforeunload = function () {
        localStorage.setItem(SessionKey._ISONLOAD, true);
    };

    async function getMarqueeTagContent() {
        //"&languageCode=" +
        //    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        //        localStorage.getItem(SessionKey._LANGUAGE) : 'en');
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_MARQUEE_TAG_CONTENT
            + "?MarqueeTagId=1"
            + "&LanguageCode=" +
            (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
                localStorage.getItem(SessionKey._LANGUAGE) : 'en')
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            responseJson[ApiKey._API_DATA_KEY].map((option) => {
                setContent(option["content"]);

            });
            //responseJson[ApiKey._API_DATA_KEY];
        }
    }

    async function init() {
        _dispatch(setBusy());

        //get all new game and recommended game
        let apiUrl =
            ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
            "?languageId=" +
            languageId +
            "&Count=" +
            14;
        // if (isLoggedIn) {
        //   apiUrl += "&memberId=" + _userData.userId;
        // }
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];
            setNewGames(newGamesData);

            let recommendedGamesData =
                responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
            setRecommendedGames(recommendedGamesData);
        } else {
            _dispatch(
                showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
        }






        //for fifa world cup
        // var apiUrl2 = ApiUrl._API_GET_WEB_CONTENT + "?Name=FIFA World Cup 2022 Groups";
        // var responseJson2 = await ApiEngine.get(apiUrl2); 
        // if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
        //   const name = responseJson2[ApiKey._API_DATA_KEY]["name"];
        //   const content = responseJson2[ApiKey._API_DATA_KEY]["content"];
        //   setTitle(name);
        //   setHtml(content);
        // }

        let currTime = Date.parse(currentTime);
        let cnyEndTimeNew = Date.parse(cnyEndTime);
        if (currTime > cnyEndTimeNew) {
            setShowTrigger(false);
        }

        _dispatch(setIdle());
    }


    const getBannerLanguage = async () => {
        let apiUrl = ApiUrl._API_GET_USER_BANNER + "?isDesktop=true&isPopup=false&languageCode=" +
            (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
                localStorage.getItem(SessionKey._LANGUAGE) : 'en');

        // localStorage.getItem(SessionKey._LANGUAGE);
        let responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            if (data.filter(x => x.displayDevice === 0).length > 0) {
                let tempMainBannerData = data.filter(x => x.displayDevice === 0);
                // let tempMainBannerData = data.filter(x => x.displayDevice === 0)[0];
                // setMainBannerData(tempMainBannerData);
                // setMainBannerImg(tempMainBannerData.desktopImage);
                if (tempMainBannerData.length > 0) {
                    setMainBannerData(tempMainBannerData);
                }
                else {
                    setMainBannerData();
                }
            }
            else {
                setMainBannerData();
            }
            let tempSliderBannerList = [];
            if (data.filter(x => x.displayDevice === 1).length > 0) {
                data.filter(x => x.displayDevice === 1).map((banner) => {
                    tempSliderBannerList.push({ image: banner.desktopImage, url: banner.url });
                });
            }
            setSliderBannerList(tempSliderBannerList);
            setIsLoaded(true);
        }
    }

    const getSportGames = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=Sports&languageCode=" + localStorage.getItem(SessionKey._LANGUAGE));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            let tempSportGames = [];
            data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
                tempSportGames.push(row);
            });
            ///* currently number of slides is equal to slidesToShow, need to add the same slides one more time for the sliding to work */
            //data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
            //  tempSportGames.push(row);
            //});
            setSportGames(tempSportGames);
            setSportGameCount(tempSportGames.length);
        }
    }

    //pass filter data
    async function handleFilterByProvider(id, count, type) {
        let apiUrl =
            ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
            "?id=" +
            id +
            "&languageId=" +
            languageId +
            "&Count=" +
            count;
        // if (isLoggedIn) {
        //   apiUrl += "&memberId=" + _userData.userId;
        // }
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (type === "newGames") {
                let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];

                setNewGames(newGamesData);
                if (isFilterNewDropdownVisible) {
                    setIsFilterNewDropdownVisible(!isFilterNewDropdownVisible);
                }
            } else if (type === "popularGames") {
                let recommendedGamesData =
                    responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
                setRecommendedGames(recommendedGamesData);
                if (isFilterPopularDropdownVisible) {
                    setIsFilterPopularDropdownVisible(!isFilterPopularDropdownVisible);
                }
            }
        } else {
            _dispatch(
                showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
        }
    }

    async function getMemberInit() {
        let member = [];
        try {
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_DASHBOARD);
            // setLoadingMemberFlag(false);
            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
            setMemberData(responseJson[ApiKey._API_DATA_KEY]);
            member = responseJson[ApiKey._API_DATA_KEY];
            if (member["nvPMIv9IddoM6dAJCV6p"] > 0) {
                let responseJson = await ApiEngine.get(ApiUrl._API_GET_FREE_SPIN_CONTENTS);
                let _temp = [];
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let data = responseJson[ApiKey._API_DATA_KEY];

                    data["itemData"].map((item) => {
                        _temp.push({
                            id: item.id,
                            option: item.name,
                            style:
                            {
                                backgroundColor: item.backgroundColor,
                                textColor: item.fontColor,
                                fontSize: item.fontSize
                            },
                            optionSize: item.optionSize,
                            amount: item.amount,
                            winChance: item.winChance
                        });
                    })
                    setWheelOuterBorderColor(data["wheelData"].filter(x => (x.key).endsWith("WheelOuterBorderColor"))[0].value);
                    setWheelInnerBorderColor(data["wheelData"].filter(x => (x.key).endsWith("WheelInnerBorderColor"))[0].value);
                    setFreeSpinItems(_temp);
                    calculateWinItem(_temp);
                    setShowFreeSpin(true);
                }
                setShowTotalSpinText(member["nvPMIv9IddoM6dAJCV6p"]);
                //let _t = [];
                //let transactionId = responseJsonCheck[ApiKey._API_DATA_KEY]["jfjisH09DJKASDJ"];
                //transactionId.map((item) => {
                //    _t.push({
                //        id: item
                //    });
                //})
                //setCountOfButton(_t.length);
                //setTxnIdListForFreeSpin(_t);
                //setShowTotalSpinText(transactionId.length);
            }
            setLoadingMemberFlag(true);
            let params = {};

            if (member["isShareHolder"] === true) {
                responseJson = await ApiEngine.post(
                    ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
                    createFormBody(params)
                );
                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var theInterval = setInterval(() => {
                        if (elePin["current"] == undefined) return;
                        
                        if (
                            elePin["current"]["values"][0] == "" ||
                            elePin["current"]["values"][0] == undefined
                        ) {
                            elePin["current"]["elements"][0].focus();
                        } else if (
                            elePin["current"]["values"][1] == "" ||
                            elePin["current"]["values"][1] == undefined
                        ) {
                            elePin["current"]["elements"][1].focus();
                        } else if (
                            elePin["current"]["values"][2] == "" ||
                            elePin["current"]["values"][2] == undefined
                        ) {
                            elePin["current"]["elements"][2].focus();
                        } else if (
                            elePin["current"]["values"][3] == "" ||
                            elePin["current"]["values"][3] == undefined
                        ) {
                            elePin["current"]["elements"][3].focus();
                        } else if (
                            eleConfirm["current"]["values"][0] == "" ||
                            eleConfirm["current"]["values"][0] == undefined
                        ) {
                            eleConfirm["current"]["elements"][0].focus();
                        } else if (
                            eleConfirm["current"]["values"][1] == "" ||
                            eleConfirm["current"]["values"][1] == undefined
                        ) {
                            eleConfirm["current"]["elements"][1].focus();
                        } else if (
                            eleConfirm["current"]["values"][2] == "" ||
                            eleConfirm["current"]["values"][2] == undefined
                        ) {
                            eleConfirm["current"]["elements"][2].focus();
                        } else if (
                            eleConfirm["current"]["values"][3] == "" ||
                            eleConfirm["current"]["values"][3] == undefined
                        ) {
                            eleConfirm["current"]["elements"][3].focus();
                        } else {
                            var currpin =
                                elePin["current"]["values"][0] +
                                elePin["current"]["values"][1] +
                                elePin["current"]["values"][2] +
                                elePin["current"]["values"][3];
                            var currconfpin =
                                eleConfirm["current"]["values"][0] +
                                eleConfirm["current"]["values"][1] +
                                eleConfirm["current"]["values"][2] +
                                eleConfirm["current"]["values"][3];
                            if (currpin != currconfpin) {
                                setInvalidPin(true);
                                elePin["current"].clear();
                                eleConfirm["current"].clear();
                                elePin["current"].focus();
                            } else {
                                setInvalidPin(false);
                                clearInterval(theInterval);
                                submitPin(currpin, currconfpin);
                            }
                        }
                    }, 50);
                    setTheInterval(theInterval);
                    setShowCreateShareholderPin(true);
                }
            }
            else
            {
                setShowCreateShareholderPin(false);
            }
            // await _dispatch(checkIsLoggedIn());
        } catch (errorMessage) {
            _dispatch(showResponseMessage(false, errorMessage));
        } finally {
            _dispatch(setIdle());
        }
        getMarqueeTagContent(member["languageSetting"]);
    }

    async function startGame(
        gameId,
        hasOwnLobby,
        masterProductId,
        gameName,
        isSeamless,
        isApp,
        appUrl,
        appDeepLink,
        isNewWindow,
        packageNameKeyword
    ) {
        setSelectedGameName(gameName);
        setMasterProductId(masterProductId);
        setIsApp(isApp);
        setAppDeepLink(appDeepLink);
        setPackageNameKeyword(packageNameKeyword);
        if (isApp && !stringIsNullOrEmpty(appUrl)) {
            setAppUrl(appUrl);
        }
        if (hasOwnLobby !== true) {
            if (isSeamless == false) {
                _dispatch(setBusy());
                var responseJson = await ApiEngine.get(
                    ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
                    "?masterProductId=" +
                    masterProductId
                );
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                    let tempFromOptions = [];
                    let tempToOptions = [];

                    responseJson[ApiKey._API_DATA_KEY].map((product) => {
                        let option = {
                            label: product.productName,
                            value: product.productId,
                            amount: parseFloat(product.balance),
                        };
                        if (product.productId === _MAIN_WALLET_ID) {
                            tempFromOptions.push(option);
                        } else if (product.productId === masterProductId) {
                            setBonus(product.bonus);
                            setCommission(product.commission);
                            if (product.productName === "D2 Poker") {
                                setClubFee(product.clubFee);
                            }
                            setGameWalletBalanceBeforeTransfer(product.balance);
                            // if (product.masterProductContent !== null) {
                            //   setMasterProductContent(product.masterProductContent);
                            // } else {
                            //   setMasterProductContent("");
                            // }
                            tempToOptions.push(option);
                        }
                    });
                    setFromOptions(tempFromOptions);
                    setToOptions(tempToOptions);

                    var mpJson = await ApiEngine(
                        ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                        "?masterProductId=" +
                        masterProductId
                    );
                    if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                        let data = mpJson[ApiKey._API_DATA_KEY];
                        setMpData(data);
                        if (window.ReactNativeWebView && isApp) {
                            window.ReactNativeWebView.postMessage(
                                JSON.stringify({
                                    action: "deeplink",
                                    url: appUrl,
                                    deeplink: appDeepLink
                                        .replace("[username]", data["username"])
                                        .replace("[password]", data["password"]),
                                    packageKeyword: packageNameKeyword,
                                })
                            );
                        }
                    }
                }
                setProductGameId(gameId);
                var isNewWindowJson = await ApiEngine(
                    ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
                );
                // setShowPregamePopup(true);

                setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
                setShowPregamePopup(true);

                _dispatch(setIdle());
            } else {
                window.open(
                    `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
                    }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
                );
            }
        } else {
            if (isSeamless == false) {
                _dispatch(setBusy());
                var responseJson = await ApiEngine.get(
                    ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
                    "?masterProductId=" +
                    masterProductId
                );
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                    let tempFromOptions = [];
                    let tempToOptions = [];

                    responseJson[ApiKey._API_DATA_KEY].map((product) => {
                        let option = {
                            label: product.productName,
                            value: product.productId,
                            amount: parseFloat(product.balance),
                        };
                        if (product.productId === _MAIN_WALLET_ID) {
                            tempFromOptions.push(option);
                        } else if (product.productId === masterProductId) {
                            setBonus(product.bonus);
                            setCommission(product.commission);
                            if (product.productName === "D2 Poker") {
                                setClubFee(product.clubFee);
                            }
                            setGameWalletBalanceBeforeTransfer(product.balance);
                            // if (product.masterProductContent !== null) {
                            //   setMasterProductContent(product.masterProductContent);
                            // } else {
                            //   setMasterProductContent("");
                            // }
                            tempToOptions.push(option);
                        }
                    });
                    setFromOptions(tempFromOptions);
                    setToOptions(tempToOptions);

                    var mpJson = await ApiEngine(
                        ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                        "?masterProductId=" +
                        masterProductId
                    );
                    if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                        let data = mpJson[ApiKey._API_DATA_KEY];
                        setMpData(data);
                    }
                }

                var isNewWindowJson = await ApiEngine(
                    ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
                );
                setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
                setShowPregamePopup(true);
                setProductGameId(gameId);
                // setShowPregamePopup(true);
                setToLobbyBtn(true);
                _dispatch(setIdle());
            }
        }
    }


    useEffect(() => {
        setDisplayGameMenu(false);
        setTimeout(() => { setDisplayGameMenu(true); setLoadingMemberFlag(false); }, 1);

    }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

    const handleDraggingStart = (e, platform) => {
        e.target.style.opacity = 0.5;

    }

    // const setMousePosition = (e, platform) => {
    //   // const currentWidthWithButton = currentWindowWidth - 25;
    //   if (platform === 'desktop') {
    //     // console.log(e.clientX);
    //     setCurrentX(e.clientX - 13.5);
    //     setCurrentY(e.clientY - 25);
    //     if (e.clientX > currentWindowWidth || e.clientX < 0 || e.clientY > currentWindowHeight || e.clientY < 0) {
    //       setCurrentX(currentWindowWidth - 25);
    //       setCurrentY(50);
    //     }
    //   }
    //   else if (platform === 'mobile') {
    //     setCurrentX(e.changedTouches[0].clientX - 13.5);
    //     setCurrentY(e.changedTouches[0].clientY - 25);
    //     //setCurrentX(e.touches[0].clientX - 10.5);
    //     //setCurrentY(e.touches[0].clientY - 21);
    //     if (e.changedTouches[0].clientX > currentWindowWidth || e.changedTouches[0].clientX < 0 || e.changedTouches[0].clientY > currentWindowHeight || e.changedTouches[0].clientY < 0) {
    //       setCurrentX(currentWindowWidth - 25);
    //       setCurrentY(50);
    //     }
    //   }
    //   // console.log(e);
    //   // console.log(e.changedTouches[0].clientX);
    //   // console.log(e.changedTouches[0].clientY);
    // }

    async function startMiniGame(miniGameCode, device) {

        let languageCode = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en';

        // mini game have their own lang id
        let miniGameLangId = 0;

        if (languageCode == "en" || languageCode == "id") {
            miniGameLangId = 0;
        }
        else if (languageCode == "zh") {
            miniGameLangId = 1;
        }
        else if (languageCode == "ms") {
            miniGameLangId = 2;
        }
        else if (languageCode == "vi") {
            miniGameLangId = 3;
        }
        else if (languageCode == "th") {
            miniGameLangId = 4;
        }

        setStartGameTime("");
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_MINI_GAME_URL +
            "?miniGameCode=" + miniGameCode +
            "&device=" + device +
            "&languageId=" + miniGameLangId 
        );
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            throw responseJson[ApiKey._API_MESSAGE_KEY];
        }
        else {
            let data = responseJson[ApiKey._API_DATA_KEY];
            if (miniGameCode == "COINMINI") {
                setCoinMiniGameUrl(data);
                setStartGameTime(moment());
                setTimeout(function () {
                    setIsLoading(false);
                }, 3000);
            }
            else if (miniGameCode == "KENOMINI") {
                setKenoMiniGameUrl(data);
                setStartGameTime(moment());
            }
            else {
                setTaiXiuMiniGameUrl(data);
                setStartGameTime(moment());
            }
        }
    }

    const calculateWinItem = async (spinItems) => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_FREE_SPIN_WINNER);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            let decodedNameStr = atob(data["zUkVuSuytw84kolnmVlA"]);
            let decodedWinName = decodedNameStr.substring(12);
            if (decodedWinName.indexOf("$") === -1) {
                setIsFreeSpinWin(false);
            }
            let decodedIdStr = atob(data["lDcTD7kC9j2GEOYekzXO"]);
            let decodedWinId = parseInt(decodedIdStr.substring(12));
            setWinningId(decodedWinId);
            var winningItem = spinItems.filter(x => x.id === decodedWinId)[0];
            setWinningOption(winningItem);
        }
        //let loopData = spinItems;
        //let prizeList = [];
        //loopData.map((item) => {
        //  let limit = Math.ceil(item.winChance);
        //  let count = 0;
        //  while (count <= limit) {
        //    count++;
        //    prizeList.push(item.id);
        //  }
        //});
        //let win = Math.ceil(Math.random() * (prizeList.length - 1));
        //let winId = prizeList[win];
        //setWinningId(winId);
        //setWinningOption(spinItems.filter(x => x.id === winId)[0]);
        //if (!isEmpty(winningOption)) {
        //    let txnId = txnIdListForFreeSpin[0];
        //    console.log(winningOption);
        //    createdRecord(txnId, winningOption.id);
        //}
    }

    async function spinInsert() {
        setStartSpinning(true);
        setShowWinningText(false);
        createdRecord();
        //setWinningOption(freeSpinItems.filter(x => x.id === winningId)[0]);
        //let x = countOfButton - 1
        //setCountOfButton(x);
    }

    async function createdRecord() {
        let params1 = {
            transactionTypeId: TransactionType._FREESPIN,
            amount: winningOption.amount,
            channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
            panel: PanelType._MEMBERSITE,
            fvy9yy73vjnwuvjgk9yx: btoa(winningId + "123456789"),
        };

        let responseJson = await ApiEngine.post(
            ApiUrl._API_CREATE_TRANSACTION,
            createMultiPartFormBody(params1)
        );

        //let tempList = txnIdListForFreeSpin.filter(x => x !== txnId);
        //setTxnIdListForFreeSpin(tempList);
        //setShowTotalSpinText(tempList.length);
    }

    return (
        <>
            {/* {showTrigger && (
        <div
          draggable={true}
          onDragStart={(e) => handleDraggingStart(e, 'desktop')}
          onTouchStart={(e) => handleDraggingStart(e, 'mobile')}
          onDragEnd={(e) => { e.target.style.opacity = 1; setMousePosition(e, 'desktop'); }}
          onTouchEnd={(e) => { e.target.style.opacity = 1; setMousePosition(e, 'mobile'); }}
          style={{ position: "fixed", left: currentX, bottom: "0", transition: "0.3s all", zIndex: "999" }}
        >
          <div
            className={dNone + ' cny-trigger-close'}
            onClick={() => {
            setShowTrigger(false);
          }}
          >
            <img src={require("../../assets/img/cny/Close_Icon.svg")} style={{ width: "20px" }} />
          </div>
          <div
            onClick={() => {
              setCnyModal(true);
            }}>
          <img
              src={require("../../assets/img/cny/cny-video-5s.gif")} style={{ width: "150px" }}
          />
          </div>
        </div>
      )} */}
            {/*Date.parse(currentTime) < Date.parse(cnyEndTime) && 
        showTrigger && (
          <div className={addRightCss + ' cny-pop-trigger'} >
            <div
              className={dNone + ' cny-trigger-close'}
              onClick={() => {
                setShowTrigger(false);
              }}>
                <img src={require("../../assets/img/cny/Close_Icon.svg")} />
            </div>
            <img
              onClick={() => {
                setCnyModal(true);
              }}
              src={require("../../assets/img/cny/cny-video.gif")}
            />
          </div>
        )
      */}
        <LiveChatModal />
            {openMiniGameModal && (
                <div className="cny-video">
                    <div className="cny-video-box">
                        <div className="mini-games-modal">
                            <div className="mini-games-dialog">
                                <div className="mini-games-header">
                                    <img
                                        src={require("../../assets/img/mini-games/header/web_"
                                            + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                                                ? localStorage.getItem(SessionKey._LANGUAGE)
                                                : 'en')
                                            + ".png")}
                                    />
                                    <button className="mini-close-button"
                                        onClick={() => {
                                            setOpenMiniGameModal(false);
                                            setCoinMiniGameUrl("");
                                            setKenoMiniGameUrl("");
                                            setTaiXiuMiniGameUrl("");
                                            setCurrentActiveTab("COINMINI");
                                            setMiniGamesFirstClicked(["COINMINI"]);
                                            setIsLoading(true);
                                        }}
                                    ></button>
                                </div>
                                <div className="mini-games-body">
                                    <Nav tabs className="mini-games-tab">
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    "mini-games-items " +
                                                    classnames({
                                                        selected:
                                                            currentActiveTab === "KENOMINI"
                                                    })}
                                                onClick={() => {
                                                    toggle("KENOMINI");
                                                }}
                                            >
                                                <img
                                                    src={require("../../assets/img/mini-games/gameIcon/KenoMini-Icon.png")}
                                                    loading="lazy" />
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    "mini-games-items " +
                                                    classnames({
                                                        selected:
                                                            currentActiveTab === "COINMINI"
                                                    })}
                                                onClick={() => {
                                                    toggle("COINMINI");
                                                }}
                                            >
                                                <img
                                                    src={require("../../assets/img/mini-games/gameIcon/CoinMini.png")}
                                                    loading="lazy" />
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    "mini-games-items " +
                                                    classnames({
                                                        selected:
                                                            currentActiveTab === 'TAIXIUMINI'
                                                    })}
                                                onClick={() => {
                                                    toggle('TAIXIUMINI');
                                                }}
                                            >
                                                <img
                                                    src={require("../../assets/img/mini-games/gameIcon/TaiXiumini.png")}
                                                    loading="lazy" />
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    {/* <TabContent
                                        activeTab={currentActiveTab}
                                        className="mini-games-section"
                                    >
                                        <TabPane
                                            tabId="KENOMINI"
                                            className="mini-games-content"
                                            style={currentActiveTab === 'KENOMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }
                                            }>
                                            <div id="KENOMINI" className="mini-games-content">
                                                {
                                                    isLoading &&
                                                    <div className="loading">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </div>
                                                }
                                                <iframe
                                                    className="iframe"
                                                    loading="lazy"
                                                    frameBorder="0"
                                                    width="248"
                                                    height="227"
                                                    title="Keno Mini"
                                                    src={kenoMiniGameUrl}
                                                >
                                                </iframe>
                                            </div>
                                        </TabPane>
                                        <TabPane
                                            tabId="COINMINI"
                                            className="mini-games-content"
                                            style={currentActiveTab === 'COINMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                                            <div id="COINMINI" className="mini-games-content">
                                                {
                                                    isLoading &&
                                                    <div className="loading">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </div>
                                                }
                                                <iframe
                                                    className="iframe"
                                                    loading="lazy"
                                                    width="248"
                                                    height="227"
                                                    title="Coin Mini"
                                                    src={coinMiniGameUrl}
                                                >
                                                </iframe>
                                            </div>
                                        </TabPane>
                                        <TabPane
                                            tabId="TAIXIUMINI"
                                            className="mini-games-content"
                                            style={currentActiveTab === 'TAIXIUMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                                            <div id="TAIXIUMINI" className="mini-games-content">
                                                {
                                                    isLoading &&
                                                    <div className="loading">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </div>
                                                }
                                                <iframe
                                                    className="iframe"
                                                    loading="lazy"
                                                    frameBorder="0"
                                                    width="248"
                                                    height="227"
                                                    title="TaiXiu Mini"
                                                    src={taiXiuMiniGameUrl}
                                                >
                                                </iframe>
                                            </div>
                                        </TabPane>
                                    </TabContent> */}
                                    <div className="more-game-img"
                                        onClick={() => {
                                            if (!isEmpty(games93Connect)) {
                                                startGame(
                                                    games93Connect["id"],
                                                    games93Connect["hasOwnLobby"],
                                                    games93Connect["masterProductId"],
                                                    games93Connect["gameName"],
                                                    games93Connect["masterProductIsSeamless"],
                                                    games93Connect["isApp"],
                                                    games93Connect["appUrl"],
                                                    games93Connect["appDeepLink"],
                                                    games93Connect["isNewWindow"]
                                                );
                                            }
                                        }}
                                    >
                                        <img
                                            src={require("../../assets/img/mini-games/moreGames/more_games_"
                                                + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                                                    ? localStorage.getItem(SessionKey._LANGUAGE)
                                                    : 'en')
                                                + ".png")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showMiniGameTrigger &&
                <>
                    <Draggable
                        bounds={{ left: 0, top: headerHeight + 50, right: 0, bottom: 200 }}
                        onDrag={(e, position) => {
                            onControlledDrag(e, position);
                            setIsDragging(true);
                        }}
                        defaultPosition={{ x: 0, y: 0 }}
                        onStop={() => {
                            if (!isDragging) {
                                if (isLoggedIn) {
                                    setOpenMiniGameModal(true);
                                    startMiniGame(currentActiveTab, "d");
                                    setGames93Connect(carouselGameList.filter(x => x.gameName === "93Connect")[0]);
                                }
                                else if (!isLoggedIn) {
                                    _dispatch(
                                        showMessage({
                                            type: AlertTypes._INFO,
                                            content: t("PLEASE_LOGIN_FIRST"),
                                        })
                                    );
                                }
                            }
                            else {
                                setIsDragging(false);
                            }
                        }}
                    >
                        <div className="cny-pop-trigger">
                            <img
                                draggable="false"
                                src={require("../../assets/img/mini-games/Web_"
                                    + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                                        ? localStorage.getItem(SessionKey._LANGUAGE) == 'en' || localStorage.getItem(SessionKey._LANGUAGE) == 'zh' || localStorage.getItem(SessionKey._LANGUAGE) == 'ms'
                                            ? localStorage.getItem(SessionKey._LANGUAGE)
                                            : 'en'
                                        : 'en')
                                    + ".png")} />
                        </div>
                    </Draggable>

                    <Draggable
                        bounds="body"
                        position={controlledPosition}
                        onStop={() => {
                            setShowMiniGameTrigger(false);
                            localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, false);
                        }}
                    >
                        <div className="cny-pop-trigger">
                            <div className={'cny-trigger-close'}>
                                <img
                                    draggable="false"
                                    src={require("../../assets/img/cny/Close_Icon.svg")} style={{ width: "20px" }} />
                            </div>
                        </div>
                    </Draggable>
                </>
            }

            {/*cnyModal && (
        <div className="cny-video">
          <div className="cny-video-box">
            <div
              className="cny-video-close"
              onClick={() => {
                setCnyModal(false);
              }}>
                <img src={require("../../assets/img/cny/Close_Icon.svg")} />
            </div>
            <video src={(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) === "zh") ? require("../../assets/img/cny/i8_cny_video_zh.mp4") : require("../../assets/img/cny/i8_cny_video_en.mp4")} autoPlay="true" width="100%" loop="true" />
          </div>
        </div>
      )*/}
            <section id="home-banner" className="position-relative">
                {mainBannerData && mainBannerData.length <= 0 ?
                    <div className="category-banner">
                        <img
                            src={mainBannerData[0].desktopImage}
                            style={displayBanner ? {} : { display: "none" }}
                            className="img-100 banner-img-radius"
                            alt="banner-main"
                            onLoad={() => setDisplayBanner(true)}
                        />
                    </div>
                    :
                    <div className="banner-slider">
                        <Slider {...bannerSlider}>
                            {mainBannerData &&
                                mainBannerData.length &&
                                map(mainBannerData, (item, index) => {
                                    return (
                                        <div key={index} className="bs-item cursor-pointer">
                                            <img
                                                src={item.desktopImage}
                                                className="img-100 banner-img-radius"
                                                alt="banner"
                                                onClick={() => {
                                                    if (isLoggedIn) {
                                                        window.open(item.url)
                                                    }
                                                    else if (!isLoggedIn) {
                                                        _dispatch(
                                                            showMessage({
                                                                type: AlertTypes._INFO,
                                                                content: t("PLEASE_LOGIN_FIRST"),
                                                            })
                                                        );
                                                    }
                                                }
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                }

                {/* {!stringIsNullOrEmpty(mainBannerImg) && mainBannerImg.includes("qatar_worldcup_banner") ?
          <>
            <div className="text-center sport-timer sport-timer-homepage">
              <div className="clock-title font-16 mb-2">{t("TOURNAMENT_STARTS_IN")}</div>
              <Count />
            </div>
            <img src={mainBannerImg} className="img-100" alt="banner-main" />
          </> : !stringIsNullOrEmpty(mainBannerImg) ? <img
          src={mainBannerImg}
          className="img-100"
          alt="banner-main"
          onClick={() => window.open(mainBannerData.url)}
        /> : <div style={{height: "35vh"}}></div>} */}
            </section>
            <section className="marquee-tag-backgroud">
                <div className="marquee-tag">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="marquee-tag-div">
                                    <img
                                        className="image"
                                        src={require("../../assets/img/icon/Announcement_icon.png")}
                                    />
                                    <marquee width="95%" direction="left" >
                                        {parse(content)}
                                    </marquee>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="category-section">
                <GameMenu isHome />
            </section>
            {displayGameMenu && (
                <>
                    <section className="newgames-section section-padding pb-0 mb-5">
                        <GameListCarousel
                            title={t("GAME_PROVIDER")}
                            gameList={carouselGameList}
                        />
                        <GameListGrid
                            title={t("NEW_GAMES")}
                            isFilterDropdownVisible={isFilterNewDropdownVisible}
                            dropdownFilterOnClick={() => {
                                setIsFilterNewDropdownVisible(!isFilterNewDropdownVisible);
                            }}
                            filterList={gameProviders}
                            gameList={newGames}
                            handleFilterByProvider={handleFilterByProvider}
                            type="newGames"
                            page="home"
                        />
                    </section>
                    {sliderBannerList.length > 0 && <section className="banner-slider-section">
                        <GameListBanner bannerList={sliderBannerList} />
                    </section>}
                    <section className="popular-section pb-0 mb-4">
                        <GameListGrid
                            title={t("POPULAR_GAMES")}
                            isFilterDropdownVisible={isFilterPopularDropdownVisible}
                            dropdownFilterOnClick={() => {
                                setIsFilterPopularDropdownVisible(
                                    !isFilterPopularDropdownVisible
                                );
                            }}
                            filterList={gameProviders}
                            gameList={recommendedGames}
                            handleFilterByProvider={handleFilterByProvider}
                            type="popularGames"
                            page="home"
                        />
                    </section>
                </>
            )}

            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad"
                isOpen={isLoggedIn && showCreateShareholderPin}
                centered
            >
                <ModalBody>
                    <form>
                        <div className="display-wrapper" style={{ textAlign: "center" }}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    onCreatePinKeyPress("{bksp}");
                                }
                            }}
                        >
                            <strong
                                className="reload-title text-yellow mb-3 d-block"
                                style={{ fontSize: "20px" }}
                            >
                                {t("NO_SHAREHOLDER_PIN_FOUND")}
                            </strong>
                            <strong
                                className="reload-title mb-3 d-block"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#002e6c",
                                }}
                            >
                                {t("ENTER_YOUR_PIN")}
                            </strong>
                            <PinInput
                                ref={elePin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={false}
                                // disabled={showCreateShareholderPin}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberFocused(index);
                                    onCreatePinKeyPress(value);
                                }}
                                onComplete={(value) => adjustFocus()}
                                inputMode="numeric"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <strong
                                className="reload-title mb-3 d-block"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#002e6c",
                                    marginTop: "30px",
                                }}
                            >
                                {t("CONFIRM_YOUR_PIN")}
                            </strong>
                            <PinInput
                                ref={eleConfirm}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={false}
                                autoSelect={false}
                                // disabled={showCreateShareholderPin}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberConfirmFocused(index);
                                    onCreatePinKeyPress(value);
                                }}
                                inputMode="numeric"
                                inputStyle={{
                                    border: "0",
                                    margin: "0px 10px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "transparent",
                                    borderBottom: "2px solid #002e6c",
                                }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <div
                                className="invalid-feedback"
                                style={{ fontSize: "12px", marginTop: "1rem" }}
                            >
                                {invalidPin && t("PIN_DOES_NOT_MATCH")}
                                {!invalidPin && <span>&nbsp;</span>}
                            </div>
                            {/* <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "<i class='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onCreatePinKeyPress}
                disableButtonHold
                disableCaretPositioning
              /> */}
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    );
};
export default Home;
