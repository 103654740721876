import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, SessionKey } from "../../util/Constant";
import { useDispatch } from "react-redux";
import { showResponseMessage } from "../../redux/AppAction";
import GameMenu from "components/game-list/game-menu";
import { useTranslation } from "react-i18next";

const VideoTutorial = (props) => {
  var _dispatch = useDispatch();
  const [video, setVideo] = useState([]);
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  async function init() {
    // var responseJson = await ApiEngine.get(
    //   ApiUrl._API_GET_VIDEO +
    //     "selectedLanguage=" +
    //     localStorage.getItem(SessionKey._LANGUAGE)
    // );
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_VIDEO);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setVideo(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [
    localStorage.getItem(SessionKey._LANGUAGE) &&
    localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
      ? localStorage.getItem(SessionKey._LANGUAGE)
      : "en",
  ]);

  return (
    <>
      {displayGameMenu && (
        <>
          <section className="category-section">
            <GameMenu />
          </section>
          <section className="section-padding text-white" id="video-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-4 pb-2">
                    <h3>i8 {t("VIDEO_TUTORIAL")}</h3>
                  </div>
                  <div className="template-box mt-3" id="video-tutorial">
                    <div className="video-banner">
                      <img
                        src={require("../../assets/img/video-banner.png")}
                        className="img-100"
                        alt="banner-main"
                      />
                    </div>
                    {/* <div className="row mt-4">
                      {video.map((video, index) => {
                        return (
                          <div className="col-4">
                            <div className="video-box">
                              <div className="first position-relative">
                                <iframe
                                  className="video"
                                  src={video["content"]}
                                  allowfullscreen="allowfullscreen"
                                  mozallowfullscreen="mozallowfullscreen"
                                  msallowfullscreen="msallowfullscreen"
                                  oallowfullscreen="oallowfullscreen"
                                  webkitallowfullscreen="webkitallowfullscreen"
                                ></iframe>
                              </div>
                              <div className="second">
                                <p className="mb-0 font-14 font-semi">
                                  {video["title"]}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default VideoTutorial;
